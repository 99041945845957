// Base button class
.btn {
  border-radius: 5px;
  padding: $spacer * 0.5 $spacer * 1.25;
  transition: all 0.2s ease-in-out;
  font-size: $font-size-base * 0.875;
  @include font-type-regular();
}

// Primary button class
.btn-primary {
  @include button-variant(
    // background
    $primary-color,
    // border
    $primary-color,
    // text-color
    $white,
    // hover-background
    lighten($primary-color, 5%),
    // hover-border
    lighten($primary-color, 5%),
    // hover-text-color
    lighten($white, 5%)
  );
}

.btn-secondary {
  @include button-variant(
    // background
    $secondary-color,
    // border
    $secondary-color,
    // text-color
    $white,
    // hover-background
    lighten($secondary-color, 5%),
    // hover-border
    lighten($secondary-color, 5%),
    // hover-text-color
    lighten($white, 5%)
  );
}

.btn-tertiary {
  @include button-variant(
    // background
    $tertiary-color,
    // border
    $tertiary-color,
    // text-color
    $white,
    // hover-background
    lighten($tertiary-color, 5%),
    // hover-border
    lighten($tertiary-color, 5%),
    // hover-text-color
    lighten($white, 5%)
  );
}

.btn-outline-primary {
  @include button-outline-variant(
    // color
    $primary-color,
    // color-hover
    $white,
    // active-background
    $primary-color,
    // active-border
    $primary-color,
    // active-color
    $primary-color
  );

  // icon
  .hi {
    color: $primary-color;
  }

  &:hover {
    .hi {
      color: $white;
    }
  }
}

.btn-outline-secondary {
  @include button-outline-variant(
    // color
    $secondary-color,
    // color-hover
    $white,
    // active-background
    $secondary-color,
    // active-border
    $secondary-color,
    // active-color
    $secondary-color
  );

  // icon
  .hi {
    color: $secondary-color;
  }

  &:hover {
    .hi {
      color: $white;
    }
  }
}

.btn-outline-tertiary {
  @include button-outline-variant(
    // color
    $tertiary-color,
    // color-hover
    $tertiary-color,
    // active-background
    $tertiary-color,
    // active-border
    $tertiary-color,
    // active-color
    $tertiary-color
  );

  // icon
  .hi {
    color: $tertiary-color;
  }

  &:hover {
    .hi {
      color: $white;
    }
  }
}

// Gradient primary button class
.btn-gradient-primary {
  background: $coral-gradient;
  color: $white;

  &:hover {
    background: $coral-gradient-lighten;
    color: $white;
  }
}

.btn-gradient-secondary {
  background: $blue-gradient;
  color: $white;

  &:hover {
    background: $blue-gradient-lighten;
    color: $white;
  }
}

.btn-gradient-tertiary {
  background: $pink-gradient;
  color: $white;

  &:hover {
    background: $pink-gradient-lighten;
    color: $white;
  }
}
