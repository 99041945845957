/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Theme variables, must be included before the libraries to allow overriding defaults
@import "styles/variables";

// 3rd party libraries
@import "bootstrap/scss/bootstrap";
@import "flag-icons/sass/flag-icons";
@import "@hivvetech/icons/font/hivve-icons";
@import "@hivvetech/icons/hivve/brands";
@import "@hivvetech/icons/sdgs/sdgs";

// base components
@import "styles/fonts";
@import "styles/bottons";
@import "styles/forms";
@import "styles/navigation";

// 3rd party overrides
@import "styles/primeng";

// Theme customization
@import "styles/theme";
