/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

html,
body {
  height: 100%;
}

body {
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-gradient {
  background-image: linear-gradient(
    90deg,
    #6cb2bc 1.89%,
    #669ab8 43.22%,
    #5f90b1 89.09%
  ) !important;
}

.dark {
  background-color: #242539;
}

.caret-up {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.caret-right {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.whitespace {
  color: $charcoal;

  &-col-1 {
    margin-right: $spacer;
  }

  &-col-2 {
    margin-left: $spacer;
  }

  &-text,
  &-img {
    width: 350px;
  }

  &-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  &-title {
    color: #3f9ec7;
  }
}
