@mixin font-type($font-family, $font-weight) {
  font-family: $font-family;
  font-weight: $font-weight;
}

@mixin font-type-medium() {
  @include font-type("Rubik Medium", 500);
}

@mixin font-type-light() {
  @include font-type("Rubik Light", 300);
}

@mixin font-type-regular() {
  @include font-type("Rubik", 400);
}

@font-face {
  font-family: "Rubik Medium";
  src: url("#{$font-path}/rubik-medium.woff2") format("woff2"),
    url("#{$font-path}/rubik-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: "Rubik Light";
  src: url("#{$font-path}/rubik-light.woff2") format("woff2"),
    url("#{$font-path}/rubik-light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: Rubik;
  src: url("#{$font-path}/rubik-regular.woff2") format("woff2"),
    url("#{$font-path}/rubik-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
