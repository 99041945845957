.nav-link {
  .hi {
    font-size: $font-size-base * 1.25;
  }
}

.dropdown-item-link {
  text-decoration: none;

  .dropdown-item-text {
    padding-left: 0;
  }
}
