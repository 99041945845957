.p-component {
  font-family: Rubik, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.p-tooltip {
  .p-tooltip-text {
    background: $primary-gradient;
    font-size: $font-size-base * 0.875;
    @include font-type-regular();
  }

  &.p-tooltip-right {
    .p-tooltip-arrow {
      border-right-color: rgba(243 109 77 / 90%);
    }
  }

  &.p-tooltip-right {
    .p-tooltip-arrow {
      border-right-color: rgba(243 109 77 / 90%);
    }
  }

  &.p-tooltip-top {
    .p-tooltip-arrow {
      border-top-color: rgba(243 109 77 / 90%);
    }
  }

  &.p-tooltip-bottom {
    .p-tooltip-arrow {
      border-bottom-color: rgba(243 109 77 / 90%);
    }
  }

  &.p-tooltip-left {
    .p-tooltip-arrow {
      border-left-color: rgba(243 109 77 / 90%);
    }
  }
}

.p-dialog {
  background-color: $white;
  box-shadow: 0 2px 5px rgba(0 0 0 / 25%);
  border: none;
  border-radius: 6px;

  &-header {
    background-color: $white;
    border-bottom: none;
    color: #3f9ec7;
    padding: $spacer * 0.75 $spacer * 1;

    &-title {
      font-size: $font-size-base;
      @include font-type-regular();
    }

    &-header-close-icon {
      color: $charcoal;
      font-size: $font-size-base * 0.875;
    }

    &-header-icon,
    &-header-icon:enabled:hover {
      color: $white;
    }
  }

  &-content {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
}

.p-datatable .p-sortable-column,
.p-datatable .p-sortable-column.p-highlight,
.p-datatable .p-datatable-thead > tr,
.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr,
.p-datatable .p-datatable-tbody > tr > td,
.p-datatable .p-datatable-tbody > tr:not(.p-highlight):hover,
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon,
.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
  color: $charcoal;
}

.p-datatable .p-sortable-column,
.p-datatable .p-sortable-column.p-highlight {
  &:focus {
    background: $white;
    box-shadow: unset;
  }

  &:hover,
  &:not(.p-highlight):hover {
    background: $white;
    color: $charcoal;
  }
}

.p-datatable .p-datatable-header,
.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr > td,
.p-datatable .p-paginator-bottom {
  border: none;
}

.p-datatable .p-datatable-header,
.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background: transparent;
  color: $charcoal;
}

.p-datatable .p-datatable-loading-overlay {
  z-index: 1050 !important;

  &.p-component-overlay {
    background-color: rgba(255 255 255 / 50%);
  }
}

.p-paginator {
  float: right;
  background: transparent;
  color: $grey;
  border: solid #dee2e6;
  border-radius: 5px;

  &.p-link:focus {
    box-shadow: unset;
  }
}

.p-paginator .p-paginator-current {
  margin-right: $spacer * 0.6;
}

// .p-paginator .p-paginator-first,
// .p-paginator .p-paginator-last,
.p-paginator .p-paginator-prev.p-disabled,
.p-paginator .p-paginator-next.p-disabled {
  display: none;
}

.p-paginator .p-paginator-pages {
  color: $grey;
  border-radius: 5px;
}

.p-paginator .p-paginator-current,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight) {
  background: transparent;
  border: none;
  color: $grey;
}

.p-paginator .p-paginator-current,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-page {
  &.p-link:focus {
    box-shadow: unset;
  }
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: $secondary-color;
  border-color: $secondary-color;
  border-radius: 5px;
  color: $white;
}

// stepper
.stepper.p-tabview {
  .p-tabview-nav {
    li {
      .p-tabview-nav-link {
        border: none;
        font-weight: normal;
        padding: $spacer * 1.5;
        padding-top: $spacer * 2.5;
      }

      .p-tabview-nav-link:not(.p-disabled):focus {
        box-shadow: none;
      }

      .tab-header-icon-container {
        position: relative;
      }

      .tab-header-icon {
        font-size: $spacer * 1.5;
        width: 50px;
        height: 50px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: linear-gradient(90deg, #4e57a6 0%, #68c9d2 100%);
        color: $white;
        margin-bottom: $spacer * 0.8;

        &::before,
        &::after {
          content: "";
          display: block;
          position: absolute;
          height: 4px;
          width: 50%;
        }

        &::before {
          left: -25px;
          background: linear-gradient(90deg, #68c9d2 0%, #4e57a6 100%);
        }

        &::after {
          right: -25px;
          background: linear-gradient(90deg, #4e57a6 0%, #68c9d2 100%);
        }
      }
    }

    .tab-header-title {
      .hi {
        color: #8ec73f;
      }
    }

    li.p-highlight {
      .tab-header-title {
        font-weight: 600;
      }
    }

    li:first-child {
      .tab-header-icon::before {
        display: none;
      }
    }

    // get second to the last child
    // as there is a hidden extra li
    li:nth-last-child(2) {
      .tab-header-icon::after {
        display: none;
      }
    }

    li.prev,
    li.p-highlight {
      .tab-header-icon {
        background: #8ec73f;
        transition: background-color 0.8s;
      }

      .tab-header-icon::before,
      .tab-header-icon::after {
        background: #8ec73f;
        transition: width 0.8s;
      }
    }
  }

  .p-tabview-panels {
    padding: 0;
  }
}
